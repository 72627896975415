<template>
 <div style="height: 100vh;display: flex;flex-direction: column;align-items: center;justify-content: center;font-size:clamp(26px, 4vw, 60px)">
 <img src="@/assets/404.png" style="width: 60%;max-width: 500px;">
  {{message}}
 </div>
</template>

<script>
export default {
  name: 'Page404',
  computed: {
    message() {
      return '找不到页面'
    }
  }
}
</script>

<style scoped>

</style>
